import React, { useState, useCallback, useEffect } from 'react'
import { CheckBoxCardSelectInput } from '../Select/CheckBoxCardSelectInput'
import classNames from 'classnames'
import {
  ContentType,
  LessonData,
  TrialLessonIndexView,
} from '../../types/trialLessons'
import { CurrentUser } from '../../types/currentUser'
import { fetchLessons } from '../../api/request'

import sheGirlRed from '../../public/she_girl_red.png'
import sheGirlBlue from '../../public/she_girl_blue.png'
import RecommendLabelRed from '../../public/recommend_label_red.png'
import RecommendLabelBlue from '../../public/recommend_label_blue.png'

import useSWRMutation from 'swr/mutation'

import styles from './index.module.scss'

import {
  ReservationStepBody,
  ReservationStepSubHeader,
} from '../ReservationStepHeader'

import { TrialLessonCalendar } from '../TrialLessonCalendar'
import { SelectedLessonTypeSummary2 } from '../SelectedLessonTypeSummary2'
import { FancyDate } from '../FancyDate'
import { UserFormPane } from '../UserFormPane'
import { SelectedLessonSummary2 } from '../SelectedLessonSummary2'

import { getLessonPeriod } from '../../utils/dateLessonPeriod'

export type LessonTypeId = 'class' | 'consultation'

export type LessonTypes = {
  id: 'class' | 'consultation'
  value: 'class' | 'consultation'
  recommendLabel: string
  sheGirl: string
  headerText: string
  label: '人気コース体験' | '少人数相談会'
  description: string
  time: string
}

export const LESSON_TYPES: LessonTypes[] = [
  {
    id: 'class',
    value: 'class',
    recommendLabel: RecommendLabelRed.src,
    sheGirl: sheGirlRed.src,
    headerText: 'SHElikesについて知りたい人におすすめ。',
    label: '人気コース体験',
    description:
      'SHElikesのサービスと学んだスキルの活用法をプチお仕事体験を通してご紹介します。\n後半のカウンセリングでは受講相談も可能です。',
    time: '90',
  },
  {
    id: 'consultation',
    value: 'consultation',
    recommendLabel: RecommendLabelBlue.src,
    sheGirl: sheGirlBlue.src,
    headerText:
      '過去に体験レッスンに参加したことがある人や少人数で具体的な相談をしたい人におすすめ。',
    label: '少人数相談会',
    description:
      'あなたに合ったコースのプランニングやご相談をお受けし、ご入会までサポートします。\n※過去に体験レッスンに参加したことがある方はこちらを選択ください。',
    time: '60',
  },
]

export function getLessonTypeSpec(lessonTypeId: LessonTypeId) {
  return LESSON_TYPES.find(t => t.id === lessonTypeId)
}

type RescheduleType = 'class' | 'consultation'
export const LessonListContent = ({
  jwtToken,
  currentUser,
  contentType,
  isRescheduled,
  rescheduleType,
  currentReservedLesson,
  reservationId,
}: {
  jwtToken: string
  currentUser?: CurrentUser
  contentType: ContentType
  isRescheduled?: boolean
  rescheduleType?: RescheduleType
  currentReservedLesson?: TrialLessonIndexView
  reservationId?: string
}): JSX.Element => {
  const [selectedLesson, setSelectedLesson] =
    useState<TrialLessonIndexView | null>(null)
  const [isAffiliate, setIsAffiliate] = useState<boolean | null>(null)

  useEffect(() => {
    if (typeof window === 'undefined') return
    const params = new URLSearchParams(window.location.search)
    const campaigns = params.getAll('utm_campaign')
    setIsAffiliate(campaigns.some(campaign => campaign.includes('_incentive')))
  }, [])

  useEffect(() => {
    if (isRescheduled) {
      onChangeSelectedLessonTypeId(rescheduleType as RescheduleType)
    }
  }, [rescheduleType, isAffiliate])

  const [selectedLessonTypeId, setSelectedLessonTypeId] =
    useState<LessonTypeId | null>(null)

  const [lessonData, setLessonData] = useState<LessonData | null>(null)

  useEffect(() => {
    if (isAffiliate === null) return
    // It should be selected otl (class) automatically.
    if (contentType === 'onlyOtl') {
      onChangeSelectedLessonTypeId('class')
      return
    }
    if (contentType === 'recommendOns') {
      LESSON_TYPES.reverse()
      return
    }
  }, [isAffiliate])

  const { trigger } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_URL}/api/v3/trial_lessons/data_for_calendar_display`,
    fetchLessons
  )

  const onChangeSelectedLessonTypeId = useCallback(
    async (id: LessonTypeId | null) => {
      setSelectedLessonTypeId(id)

      if (!id) {
        setSelectedLesson(null)
        return
      }
      setLessonData(null)
      if (isAffiliate === null) return
      try {
        const result = await trigger({
          lesson_type_name:
            id === 'class' ? 'webデザイン/マーケ・ライター' : '少人数相談会',
          ...getLessonPeriod({ boundaryTime: 20 }),
          is_affiliate: isAffiliate,
        })
        setLessonData(result.body as LessonData)
      } catch (e) {
        // error handling if necessary
      }
    },
    [selectedLesson, isAffiliate]
  )

  const isOnly = contentType.includes('only')

  return (
    <>
      <div className={styles.stepWrapper}>
        <h2 className={styles.headerText}>
          ご希望の参加日時を
          <br className={styles.sp} />
          お選びください。
        </h2>
        <ReservationStepBody>
          {selectedLesson?.id ? (
            <div className={styles.selectorWrapper} id="selector-wrapper">
              <div className={styles.lessonSummary}>
                <FancyDate date={selectedLesson.starts_at} />
                <div className={styles.summaryBody}>
                  {!isOnly && !isRescheduled && (
                    <SelectedLessonTypeSummary2
                      selectedLessonTypeId={
                        selectedLessonTypeId as LessonTypeId
                      }
                      onUnselect={() => onChangeSelectedLessonTypeId(null)}
                    />
                  )}
                  <div className={styles.summaryWrapper}>
                    <SelectedLessonSummary2
                      lesson={selectedLesson}
                      onUnselect={() => {
                        setSelectedLesson(null)
                        window.scroll({
                          top: (
                            window.document.querySelector(
                              '#selector-wrapper'
                            ) as HTMLElement
                          ).offsetTop,
                          behavior: 'smooth',
                        })
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {!isRescheduled && (
                <div
                  className={`${styles.selectorWrapper} ${classNames({
                    // onlyOtl needs to hide radio buttons because it's only selectable otl
                    [styles.only]: isOnly,
                  })}`}
                  id="trial-content-selector"
                >
                  <CheckBoxCardSelectInput
                    options={LESSON_TYPES.map(t => ({
                      label: t.label,
                      value: t.value,
                      recommendLabel: t.recommendLabel,
                      sheGirl: t.sheGirl,
                      headerText: t.headerText,
                      htmlDataValue: `area-${t.id}`,
                      description: t.description,
                    }))}
                    value={selectedLessonTypeId}
                    onChange={onChangeSelectedLessonTypeId}
                    widthType="m"
                    arrangementType="horizon"
                    gaSendEventCategory="trial"
                    gaSendEventEventName="begin_checkout_step2"
                    addGaSendEventEventNameDetail={true}
                    contentType={contentType}
                  />
                </div>
              )}
              {selectedLessonTypeId && (
                <>
                  <div className={styles.selectorWrapper}>
                    <div className={styles.lessonInfo}>
                      「{getLessonTypeSpec(selectedLessonTypeId)?.label}
                      」所要時間：
                      {getLessonTypeSpec(selectedLessonTypeId)?.time}分
                    </div>
                    <ReservationStepSubHeader>
                      ご希望の開始日時をカレンダーから選択してください
                      {
                        /* JST と異なるタイムゾーンからのアクセスの方のみ表示しておく */
                        new Date().getTimezoneOffset() !== -540 && (
                          <>
                            <br /> カレンダーの日時は日本時間となります
                          </>
                        )
                      }
                    </ReservationStepSubHeader>
                    <TrialLessonCalendar
                      setSelectedLesson={setSelectedLesson}
                      lessonData={lessonData || undefined}
                      currentReservedLesson={currentReservedLesson}
                      isRescheduled
                    />
                  </div>
                </>
              )}
            </>
          )}
        </ReservationStepBody>
      </div>
      {selectedLesson && (
        <div className={styles.stepWrapper}>
          <UserFormPane
            stepText="STEP2"
            id="step2"
            jwtToken={jwtToken}
            selectedLesson={selectedLesson}
            currentUser={currentUser}
            selectedLessonTypeId={selectedLessonTypeId}
            isRescheduled={isRescheduled}
            reservationId={reservationId}
          />
        </div>
      )}
    </>
  )
}
